<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div class="form-group mb-5">
            <label for="">Passphrase</label>
            <input
              type="password"
              name="passphrase"
              class="form form-control"
              autocomplete="off"
            />
          </div>
          <div class="form-group mb-5">
            <label for="">Upload file .pdf yang akan di TTE</label>
            <input type="file" name="file" class="form form-control" />
          </div>
          <div class="form-group mb-5">
            <button class="btn btn-primary">Submit</button>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import Service from "@/services/base.service";

export default {
  components: {},
  data() {
    return {
      loading: false,
      columns: [
        { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "user.username", title: "User" },
        { field: "nik", title: "NIK" },
        { field: "status", title: "STATUS" },
        { field: "actions", title: "Actions" },
      ],
    };
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "esign-create" });
          break;
        case "view":
          this.$router.push({
            name: "esign-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        default:
          break;
      }
      console.log("action", value);
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("esign");
        await BaseService.deleteData(id);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    },
  },
};
</script>
